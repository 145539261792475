<template>
  <div class="auth-main">
    <el-tabs v-model="activeName" :before-leave="beforeSwitch">
      <el-tab-pane label="账号密码登录" name="login1">
        <el-form ref="loginForm" :model="loginData" :rules="rules">
          <el-form-item prop="account">
            <el-input v-model="loginData.account" placeholder="请输入账号"
                      prefix-icon="iconfont icon-shouji4"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="loginData.password" placeholder="请输入密码"
                      prefix-icon="iconfont icon-lock"
                      @keyup.native="onPasswordEnter"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="doLogIn" class="login-button" type="primary" @click="loginClick">登 录</el-button>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="loginData.remember">记住密码</el-checkbox>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="其他登录" name="login2">
        扫码登录
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'

export default {
  data() {
    return {
      activeName: 'login1',
      loginData: {
        account: "",
        password: ""
      },
      // 表单验证
      rules: {
        account: [
          {required: true, message: '输入账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '输入密码', trigger: 'blur'},
        ],
      },
      doLogIn: false
    }
  },
  computed: {
    ...mapGetters([
      'userBankChoice', 'curChooseBankId'
    ]),
  },
  methods: {
    ...mapMutations([
      'handleUserInfo', 'storeUserBankChoice'
    ]),

    registerClick() {
      this.$message.error('功能暂未开放。')
    },
    // tab标签切换前调用,activeName, oldActiveNam
    beforeSwitch(active, old) {
      if (active == 'login2') {
        this.$message.error('功能暂未开放。')
        return false
      }
    },
    // 点击登录按钮
    loginClick() {
      // 调用组件的数据验证方法
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('登录失败，请检查必填项数据！')
        }
      })
    },
    // 实际登录请求
    async formSubmit() {
      this.doLogIn = true
      let userInfo = await this.$api.submitLoginInfo(this.loginData).catch(() => {
        this.doLogIn = false
        this.initRespErrMsgBoxMark()
      })

      if (userInfo) {
        localStorage.setItem('user_info', JSON.stringify(userInfo))
        this.handleUserInfo(userInfo)
        // this.storeUserBankChoice([123]);
        this.setDefaultBank()
        this.$message.success('登录成功！')
        this.doLogIn = false
        this.go()
      }
    },

    /***
     * todo 修改 弃用
     * @returns {Promise<void>}
     */
    async setDefaultBank() {
      let bankInfo = this.userBankChoice;
      if (bankInfo && bankInfo.bank_id) {
        this.putBankChoiceToStore(bankInfo.bank_id);
      } else {
        let {list} = await this.$api.getQuestionBankSysList();
        if (list && list.length > 0) {
          let defaultQbId = list[0].id;
          this.putBankChoiceToStore(defaultQbId);
        }
      }
    },

    async putBankChoiceToStore(bankId) {
      let param = {
        "qb_id": bankId
      };
      let {info} = await this.$api.chooseQuestionBank(param);
      if (info) {
        this.storeUserBankChoice(info)
      }
    },

    // 路由跳转
    go(url = '/') {
      this.$router.push(url);
    },
    // 初始化响应错误弹窗标志
    initRespErrMsgBoxMark() {
      this.$store.commit('changeRespErrMsgBoxMark', false)
    },

    onPasswordEnter(e) {
      let keyCode = e.keyCode
      if (keyCode !== 13) {
        return
      }
      this.loginClick();
    }
  },
  mounted() {
    this.initRespErrMsgBoxMark()
  },
}
</script>

<style lang="stylus" scoped>
.auth-main
  width 368px
  margin 0 auto

.el-tabs__nav-wrap::after
  height 0px

.el-tabs__nav
  display inline-block
  float none

.el-tabs__header
  text-align center

.el-input__prefix
  left 8px

.el-tabs__header
  margin-bottom 24px

.el-input__icon
  line-height 100%

.login-button
  width 100%

.login-register
  float right
  text-decoration none
  color #1890ff

.el-checkbox, .el-checkbox__input.is-checked + .el-checkbox__label
  color rgba(0, 0, 0, 0.65)

#tab-login2
  height 0px
</style>
